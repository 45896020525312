<template>
  <div>
    <loading-component
      v-if="loading"
    />
    <error-component
      v-else-if="error"
      :error="error"
    />
    <app-table-view v-else>
      <template #header>
        <slot-list-header-admin />
      </template>
      <template #table>
        <slot-list-table-admin />
      </template>
    </app-table-view>
  </div>
</template>

<script>
import {
  onMounted, provide, ref, watch,
} from '@vue/composition-api'
import useSlotList from '@/views/utils/slot/useSlotList'
import useAdminTask from '@/views/utils/task/useAdminTask'
import AppTableView from '@/views/layouts/AppTableView.vue'
import SlotListHeaderAdmin from '@/views/components/table/headers/SlotListHeaderAdmin.vue'
import SlotListTableAdmin from '@/views/components/table/tables/SlotListTableAdmin.vue'
import useDate from '@/views/utils/useDate'

export default {
  components: {
    SlotListHeaderAdmin,
    SlotListTableAdmin,
    AppTableView,
  },
  setup() {
    const {
      setCurrentSlots,
    } = useSlotList()
    const {
      loading,
      error,
      fetchSlotList,
    } = useAdminTask()

    const { parseDateRange, getDateRangeFromToday, dateRangeConjunction } = useDate()
    const defaultDateRange = getDateRangeFromToday(7, dateRangeConjunction)
    const dateFilter = ref(defaultDateRange)
    // provide date filter data for DateRangeFilter component
    provide('dateFilter', dateFilter)
    provide('defaultDateRange', defaultDateRange)

    // provide search input data for SearchBar component
    const searchQuery = ref('')
    provide('searchQuery', searchQuery)

    // fetch data from API when search event emitted or date filter value changed
    watch([searchQuery, dateFilter], async () => {
      const slots = await fetchSlotList(searchQuery.value, ...parseDateRange(dateFilter.value))
      setCurrentSlots(slots)
    })

    onMounted(async () => {
      const slots = await fetchSlotList()
      setCurrentSlots(slots)
    })

    return {
      loading,
      error,
    }
  },
}
</script>
