<template>
  <div class="campaign-header">
    <div class="header-row">
      <filter-group-toggle/>
    </div>
    <div class="header-row">
      <!-- channel name filter-->
      <div class="select-option">
        <v-select
          v-model="channelFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          placeholder="채널계정 검색"
          :options="channelOptions"
        />
      </div>

      <!-- type filter-->
      <div class="select-option-small">
        <v-select
          v-model="typeFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          multiple
          label="name"
          placeholder="광고 형식 검색"
          :options="typeOptions"
        />
      </div>

      <!-- Search Bar -->
      <search-bar
        placeholder="지면명 검색"
      />
      <!-- date filter-->
      <date-range-filter
        show-checkbox-options
        placeholder="운영기간"
      />
    </div>
  </div>
</template>
<script>

import { inject } from '@vue/composition-api'
import vSelect from 'vue-select'
import DateRangeFilter from '@/views/components/table/DateRangeFilter.vue'
import SearchBar from '@/views/components/table/SearchBar.vue'
import FilterGroupToggle from '@/views/components/table/FilterGroupToggle.vue'

export default {
  components: {
    vSelect,
    FilterGroupToggle,
    SearchBar,
    DateRangeFilter,
  },
  setup() {
    const channelOptions = inject('channelOptions')
    const channelFilter = inject('channelFilter')
    const typeFilter = inject('typeFilter')
    const typeOptions = [
      { name: '이미지 광고', value: 'IMAGE' },
      { name: '동영상 광고', value: 'VIDEO' },
      { name: '검색 광고', value: 'SEARCH' },
    ]

    return {
      channelOptions,
      channelFilter,
      typeOptions,
      typeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~flatpickr/dist/flatpickr.css';
</style>
